<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
  >
    <v-row>
      <p class="description">
        {{ $t('message.account_step_1_desc') }}
      </p>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-text-field
          v-model="login"
          :rules="loginRules"
          :label="$t('message.temp_login')"
          type="login"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :label="$t('message.temp_password')"
          type="password"
        ></v-text-field>
      </v-col>
    </v-row>

    <router-link to="/connexion" class="recovery">{{ $t('message.back_to_login') }}</router-link>

    <button type="submit" :disabled="!valid" class="btn">{{ $t('message.next') }}</button>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import Vue from 'vue'
import config from '../../config'

export default {
  name: 'AccountSetupStep1',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    login: '',
    password: ''
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.activation_step_1')
  },
  computed: {
    loginRules () {
      return [
        v => !!v || this.$t('message.mandatory_login')
      ]
    },
    passwordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.accountSetupStep1()
      }
    },
    accountSetupStep1 () {
      this.valid = false

      const data = {
        login: this.login,
        password: this.password
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/account-setup/step-1', formData)
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            const token = data.token

            if (token) {
              this.$router.push({ name: 'AccountSetupStep2', query: { token: token } })
              this.errors = []
            }
          }
        })
        .catch(e => {
          const response = e.response
          const data = response.data

          if (response.status === 400 || response.status === 404) {
            this.errors = data.violations
          }

          if (response.status === 401 && data.message === 'Account locked.') {
            this.errors = [{ title: this.$t('message.account_blocked') }]
          }

          this.successMessage = null
          this.valid = true
        })
    }
  }
}
</script>
